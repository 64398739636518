import React from 'react'
import { config } from './../config';
import { Box } from '@material-ui/core';
import { Post, PostLoading } from './posts';
import { LoadingPage } from './loading';

export function PostsView({post_pools, owned, full, sort, empty_content}) {
    const isReady = () => {
        for (const post_pool of post_pools) {
            if (post_pool.posts === null) {
                return false
            }
        }
        return true
    }

    if (!isReady()) {
        return <LoadingPage/>
    }

    const loadMore = (post) => {
        for (const post_pool of post_pools) {
            if (post_pool.posts.includes(post)) {
                post_pool.requestMore()
                return
            }
        }

        throw Error('loadMore: Could not find post within pools.')
    }

    const getPoolsWithMore = () => {
        let results = []
        for (const pool of post_pools) {
            if (pool.noMore === false) {
                results.push(pool)
            }
        }

        return results
    }

    const getPosts = (noMore) => {
        let posts = []

        for (var idx = 0; idx < post_pools.length; idx++) {
            for (const post of post_pools[idx].posts) {
                post['pool'] = idx
            }
        }

        for (const post_pool of post_pools) { 
            posts = posts.concat(post_pool.posts)
        }
        
        posts.sort(sort)

        let tmp = {}
        if(!noMore) {
            const pools_with_more = getPoolsWithMore()
            for (idx = posts.length - 1; idx >= 0; idx--) {
                const post = posts[idx]
                if (post_pools[post.pool].noMore === false) {
                    tmp[post.pool] = true
                }

                if (Object.keys(tmp).length === pools_with_more.length) {
                    break;
                }

                posts.pop()
            }
        }

        return posts
    }
    
    const getLoadTriggeringPosts = () => {
        let results = []

        for (const post_pool of post_pools) {
            results.push(
                post_pool.posts[
                    post_pool.posts.length - config.posts.fetch.trigger_offset
                ]
            )
            results.push(
                post_pool.posts[
                    post_pool.posts.length - 1
                ]
            )
        }

        return results
    }

    const getNoMore = () => {
        let result = true
        for (const post_pool of post_pools) {
            result = result && post_pool.noMore
        }
        return result
    }

    const noMore = getNoMore()
    const posts = getPosts(noMore)
    const triggers = getLoadTriggeringPosts()

    return ( posts.length > 0?
        <Box paddingBottom='1em'>
            {
                posts.map((post, index) => (
                    triggers.includes(post)? (
                        <Post 
                            key={post.id || index} 
                            post={post}
                            owned={owned}
                            full={full}
                            onVisibilityChange={(e) => e? loadMore(post) : null}
                        />
                    )
                    : (
                        <Post 
                            key={post.id || index} 
                            post={post}
                            owned={owned}
                            full={full}
                        />
                    )
                ))
            }
            {!noMore? <PostLoading key='loading'/> : null}
        </Box>
        : !noMore ?
            <PostLoading key='loading'/>
        : empty_content
    )
}

PostsView.defaultProps = {
    owned: false,
    full: false,
    sort: (a, b) => b.created - a.created,
    empty_content: <p>There are no posts.</p>,
}