import React, { useState, useEffect } from 'react'
import { Snackbar } from '@material-ui/core'

export function useAlert() {
    const [snackbar, setSnackbar] = useState(null)
    const [duration, setDuration] = useState(null)
    const [component, setComponent] = useState(null)
    const [open, setOpen] = useState(false)

    const alert = (component, duration=1500) => {
        setDuration(duration)
        setComponent(component)
        setOpen(true)
    }

    useEffect(() => {
        setSnackbar(
            <Snackbar 
                open={open} 
                autoHideDuration={duration}
                onClose={e=>setOpen(false)}
            >
                {component}
            </Snackbar>
        )
    }, [duration, component, open, setOpen])

    return [snackbar, alert]
}