import React, { useMemo, useContext, useEffect, useState, useRef, useCallback } from 'react'
import { Box, makeStyles, IconButton } from '@material-ui/core';
import { SearchContext } from '../elements/search';
import { ScrollContainer, ScrollHeader } from '../elements/scroller';
import { usePostsElastic, useUsersElastic } from '../hooks/search';
import { LoadingPage } from '../elements/loading';
import ClearIcon from '@material-ui/icons/Clear';
import { useLocation } from 'react-router';
import { PostsView } from '../elements/posts_view';
import { UsersView } from '../elements/users_view';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/functions';
import { config } from '../config';

const useStyles = makeStyles({
    search_page: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: 4,
        backgroundColor: '#f9f9f9',
    },
    header: {
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #fafafa',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)',
        justifyContent: 'space-between',
        textIndent: '1em',
    },
    title: {
        fontSize: '1.2em',
        fontWeight: 200,
    },
    title_title: {
        display: 'contents',
        fontWeight: 400,
    },
    message: {
        margin: '1em 0',
        fontSize: '1.2em',
        fontWeight: 200,
    },
    button: {
        marginRight: '.5em',
    },
})

export function useSearchPosts(term) {
    const basic_limit = config.posts.fetch.amount
    const [noMore, setNoMore] = useState(false)
    const [limit, setLimit] = useState(basic_limit)
    const [posts, setPosts] = useState(null)

    const postsRef = useRef([]);
    postsRef.current = posts ?? [];

    const lastTermRef = useRef(term);

    useEffect(() => {
        let cancel = false;
        const search = firebase.functions().httpsCallable('searchPosts');

        if (lastTermRef.current !== term) {
            lastTermRef.current = term;
            postsRef.current = [];
            setPosts(null);
            setNoMore(false);
        }

        const size = limit - postsRef.current.length;
        search({
            size,
            from: postsRef.current?.length,
            text: term,
        }).then((result) => {
            if (cancel) return;

            const hits = result.data;
            const newPosts = hits.map((hit) => ({
                id: hit.id ?? '',
                public: hit.public,
                space: hit.owner,
                created: new Date(hit.createdAt),
                updated: new Date(hit.updatedAt),
                published: new Date(hit.publishedAt),
                text: hit.text,
            }));

            setPosts(old => [...(old ?? []), ...newPosts]);
            setNoMore(newPosts.length < size);
        });


        return () => {
            cancel = true;
        }
    }, [basic_limit, limit, term])

    const requestMore = useCallback(() => {
        if (noMore) {
            return false;
        }

        setLimit(limit => limit + basic_limit)
        return true;
    }, [noMore, basic_limit])

    return useMemo(() => ({posts, requestMore, noMore}), [posts, requestMore, noMore]);
}

function useSearchUsers(term) {
    const [query, setQuery] = useState(null)

    useEffect(() => {
        if (!term) return;
        if (term.length >= 2) {
            setQuery({
                query: {
                    match: {
                        name: {
                            query: term,
                        }
                    }
                }
            })
        }
        return () => setQuery(null)
    }, [term])

    return useUsersElastic(query)
}


export function SearchPage() {
    const styles = useStyles()
    const [term, setTerm] = useContext(SearchContext)
    const [delayedTerm, setDelayedTerm] = useState(term);
    const location = useLocation()
    const users = useMemo(() => ({ users:[], noMore: true }), []); // useSearchUsers(delayedTerm)
    const posts = useSearchPosts(delayedTerm)

    useEffect(() => {
        const timeout = setTimeout(() => setDelayedTerm(term), 200);

        return () => clearTimeout(timeout);
    }, [term]);

    useEffect(() => {
        setTerm('')
    }, [location, setTerm])

    const filteredUsers = users.users ? users.users.filter(user => !user.is_old) : null

    return (
        <Box className={styles.search_page} display={term.length >= 2? 'block' : 'none'}>
            <ScrollContainer> 
                <ScrollHeader min_height={45} max_height={45}>
                    <Box className={styles.header}> 
                        <Box className={styles.title}>
                            <Box className={styles.title_title}>
                                Search
                            </Box>
                            &nbsp;"{term}"
                        </Box>
                        <IconButton 
                            classes={{root: styles.button}}
                            onClick={() => setTerm('')}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Box>
                </ScrollHeader>
                <UsersView users={filteredUsers}/>
                { users.users && posts.posts && posts.posts.length > 0?
                    <PostsView 
                        key={'search-posts'}
                        post_pools={[posts]}
                        full={true}
                        sort={(a,b) => 1}
                    />
                : posts.posts && posts.noMore && filteredUsers !== null && filteredUsers.length === 0 ?
                    <div className={styles.message}>Sorry, we could not find any insights matching your search query :(</div>
                : (users.users === null || posts.posts === null)?
                    <LoadingPage/>
                : null}
            </ScrollContainer>
        </Box>
    )
}