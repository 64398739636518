import React from 'react';
import Menu, {MenuProvider} from './menu';
import View from './view';
import Header from './header';
import Content from './content';
import './page.css';

function Page(props) {
    return (
        <div className='chitalib-page'>
            <MenuProvider>
                {props.children}
            </MenuProvider>
        </div>
    )
}

export function GenericPage({header, menu, content, foreColor, backColor}) {
    return (
        <Page>
            <Header foreColor={foreColor} backColor={backColor}>{header}</Header>
            <View>
                <Menu>{menu}</Menu>
                <Content>{content}</Content>
            </View>
        </Page>
    )
}
GenericPage.defaultProps = {
    foreColor: '#000',
    backColor: '#fff',
}

export function ShowcasePage({header, content, foreColor, backColor}) {
    return (
        <Page>
            <Header foreColor={foreColor} backColor={backColor}>{header}</Header>
            <View>
                <Content>{content}</Content>
            </View>
        </Page>
    )
}
GenericPage.defaultProps = {
    foreColor: '#000',
    backColor: '#fff',
}