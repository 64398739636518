import React from 'react'
import {Box, Button, makeStyles} from '@material-ui/core'
import {Link, useLocation} from 'react-router-dom'
import { useCurrentUser, useUserInfo } from './../hooks/user';
import { useMenuToggle } from './../chitalib/menu';
import { IntegrationButton } from './integrate';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeedOutlined';
// import EmojiObjectsIcon from '@material-ui/icons/EmojiObjectsOutlined';
import ExploreIcon from '@material-ui/icons/ExploreOutlined';
import FiberNewIcon from '@material-ui/icons/FiberNewOutlined';

export function SiteMenu(props) {
    const user = useCurrentUser()
    const userInfo = useUserInfo(user)

    return (
        <Box display='flex' flexDirection='column' width='100%' height='100%'>
            <Box my={1}>
                <MenuButton to='/random' startIcon={<ExploreIcon/>}>Explore Random</MenuButton>
                <MenuButton to='/explore' startIcon={<FiberNewIcon/>}>Explore Newest</MenuButton>
            </Box>
            { user ? 
            <>
            <hr/>
            <Box my={1}>
                <MenuButton 
                    to={userInfo && userInfo.nick ? `/space/${userInfo.nick.replace('%', '.')}` : '/404'}
                    startIcon={<AccountCircleIcon/>}
                >
                    My Space
                </MenuButton>
                { /* 
                    // TODO ADD SUPPORT FOR THIS AGAIN
                    <MenuButton to='/feed' startIcon={<DynamicFeedIcon/>}>Feed</MenuButton>
                */ }
            </Box>
            </>
            : null}
            <Box flex={1}/>
            {/* Bottom Section */}
            <Box my={1} display='flex' flexDirection='column'>
                <IntegrationButton/>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles({
    button: {
        justifyContent: 'start',
        '&$disabled': {
            color: '#000',
            opacity: 0.25,
        }
    },
    disabled: {},
})

function MenuButton({children, to, startIcon}) {
    const location = useLocation()
    const toggle = useMenuToggle()
    const styles = useStyles()

    return (
        <Link to={to}>
            <Button
                fullWidth
                size='large'
                disabled={location.pathname === to}
                disableRipple={true}
                onClick={toggle}
                classes={{
                    root: styles.button,
                    label: styles.button,
                    disabled: styles.disabled,
                }}
                startIcon={startIcon}
            >
                {children}
            </Button>
        </Link>
    )
}