import React from 'react';

const DATE_UPDATE_INTERVAL = 30000; // 5 seconds

// Class 
export class RelativeDate extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			dateRepresentation : this.getRelativeDateString(),
		};
	}
	
	render() {
		return (
			this.state.dateRepresentation
		);
	}
	
	componentDidMount() {
		this.timer = setInterval(() => {
			this.setState({
				dateRepresentation : this.getRelativeDateString() 
			});
		}, DATE_UPDATE_INTERVAL);
	}
	
	componentWillUnmount() {
		clearInterval(this.timer);
	}
	
	getRelativeDateString() {
		const now = new Date();
		const date = new Date(this.props.date);
		
		const diffMs = (now - date); // milliseconds
		//var diffDays = Math.floor(diffMs / 86400000); // days
		const diffHrs = Math.floor(diffMs / 3600000); // hours
		const diffMins = Math.round(diffMs / 60000); // minutes
		
		if (diffMins <= 0) {
			return 'Now';
		}
		
		if (diffMins < 25) {
			return '' + diffMins + ' minutes ago';
		}
		
		if (diffMins < 50) {
			return 'About 30 minutes ago';
		}
		
		if (diffMins < 120) {
			return 'About an hour ago';
		}
		
		if (diffHrs < 24) {
			return 'About ' + diffHrs + ' hours ago';
		}
		
		return this.formatDate();
	}

	formatDate() {
		const date = new Date(this.props.date);
		return date.toLocaleDateString('en-GB', {
		    day : 'numeric',
		    month : 'short',
		    year : 'numeric',
		    
		    hour : '2-digit',
		    minute : '2-digit'
		});
	}
}

export class RelativeShortDate extends RelativeDate {
	formatDate() {
		const date = new Date(this.props.date);
		return date.toLocaleDateString('en-GB', {
		    month : 'short',
		    year : 'numeric',
		});
	}
}