import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import { useExplorePosts } from '../hooks/post';
import { PostsView } from './../elements/posts_view';
import { ScrollContainer, ScrollHeader } from '../elements/scroller';
import { WriteSomethingButton } from './space';
import { config } from '../config';
import { useRandomPosts } from './../hooks/post';

const useStyles = makeStyles({
    header: {
        height: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #fafafa',
        textIndent: '1em',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)',
    },
    title: {
        fontSize: '1.2em',
        fontWeight: 200,
    },
    title_title: {
        display: 'contents',
        fontWeight: 400,
    },
    button: {

    }
})

export function ExplorePage() {
    const styles = useStyles()
    const posts = useExplorePosts()

    return (
        <ScrollContainer key={'explore'}>
            <ScrollHeader 
                min_height={config.header.standard_height}
                max_height={config.header.standard_height}
            >
                <Box className={styles.header}> 
                    <Box className={styles.title}>
                        <Box className={styles.title_title}>
                            Explore
                        </Box>
                        &nbsp;the newest insights
                    </Box>
                    <Box className={styles.button}>
                        <WriteSomethingButton/>
                    </Box>
                </Box>
            </ScrollHeader>
            <PostsView 
                key={'explore-posts'}
                post_pools={[posts]}
                full={true}
                sort={(a,b) => b.published - a.published}
            />
        </ScrollContainer>
    )
}

export function ExploreRandomPage() {
    const styles = useStyles()
    const posts = useRandomPosts()

    return (
        <ScrollContainer key={'explore'}>
            <ScrollHeader 
                min_height={config.header.standard_height}
                max_height={config.header.standard_height}
            >
                <Box className={styles.header}> 
                    <Box className={styles.title}>
                        <Box className={styles.title_title}>
                            Explore
                        </Box>
                        &nbsp;random insights
                    </Box>
                    <Box className={styles.button}>
                        <WriteSomethingButton/>
                    </Box>
                </Box>
            </ScrollHeader>
            <PostsView 
                key={'explore-posts'}
                post_pools={[posts]}
                full={true}
                sort={(a,b) => 1}
            />
        </ScrollContainer>
    )
}