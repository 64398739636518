import React, {createContext, useContext, useReducer, useEffect} from 'react';
import './menu.css';
import {ReactComponent as Extend} from './resources/extend.svg'

const MenuContext = createContext();
const MenuContextDispatcher = createContext();

export function MenuProvider({children}) {
    const [state, dispatch] = useReducer((state, action)=> {
        switch(action.type) {
            case 'toggle':
                return { ...state, 'toggled': !state['toggled']}
            case 'init':
                return { ...state, 'available': true}
            case 'finit':
                return { ...state, 'available': false}
            default:
                throw new Error()
        }
    }, {available: false, toggled: false})

    return (
        <MenuContext.Provider value={state}>
            <MenuContextDispatcher.Provider value={dispatch}>
                {children}
            </MenuContextDispatcher.Provider>
        </MenuContext.Provider>
    )
}

export function MenuToggle({color}) {
    const state = useContext(MenuContext)
    const dispatch = useContext(MenuContextDispatcher)

    if (!state['available'])
        return null

    const toggleMenu = () => {
        dispatch({type: 'toggle'})
    }

    return <Extend 
        className='chitalib-menu-toggle'
        onClick={toggleMenu}
        fill={color}
    />
}
MenuToggle.defaultProps = {
    color: '#000',
}

export function useMenuToggle() {
    const state = useContext(MenuContext)
    const dispatch = useContext(MenuContextDispatcher)

    const toggle = (value=null) => {
        if (state['available']) {
            if (value === null || value !== state['toggled']) {
                dispatch({type: 'toggle'})
            }
        }
    }

    return toggle
}

export default function Menu({children}) {
    const state = useContext(MenuContext)
    const dispatch = useContext(MenuContextDispatcher)

    useEffect(() => {
        dispatch({type: 'init'})
        return () => {
            dispatch({type: 'finit'})
        };
    }, [dispatch])

    const toggle = () => {
        dispatch({type: 'toggle'});
    }
    return (
        <>
            <aside
                className='chitalib-menu'
                data-toggled={state['toggled']}
            >
                {children}
            </aside>
            <div
                className='chitalib-menu-shadower'
                data-toggled={state['toggled']}
                onClick={toggle}
                onTouchStart={toggle}
            />
        </>
    )
}
Menu.defaultProps = {}