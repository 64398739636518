import React from 'react'
import firebase from 'firebase/app';
import 'firebase/analytics';
import { ButtonBase, Button } from '@material-ui/core'
import { useDialog } from '../hooks/dialog'
import { useCurrentUser, loginWithGoogle } from '../hooks/user'

export function RequireLoginButton(props) {
    const me = useCurrentUser()
    const [dialog, showDialog] = useDialog()

    const handleClick = (e) => {
        if (me === false) {
            firebase.analytics().logEvent('login_required_dialog_open')

            // LOGIN
            showDialog(
                'Login is Required',
                'continue with Google to complete the action.',
                (close) => (
                    <>
                        <Button
                            onClick={() => {
                                firebase.analytics().logEvent('login_required_dialog_cancel')
                                close()
                            }}
                        >Cancel</Button>
                        <Button
                            onClick={() => {
                                loginWithGoogle()
                                .then((details) => {
                                    firebase.analytics().logEvent('login_required_dialog_continue')
                                    props.onClick(e, details.user)
                                })
                                .finally(() => {
                                    close()
                                })
                            }}
                            variant='contained'
                            color='secondary'
                        >Continue with Google</Button>
                    </>
                )
            )
        }
        else {
            props.onClick(e, me)
        }
    }

    return (
        <>
            <props.component {...props} onClick={handleClick}>
                {props.children}
            </props.component>
            {dialog}
        </>
    )
}

RequireLoginButton.defaultProps = {
    component: ButtonBase,
}