import React, { useState, useEffect, useMemo } from 'react'
import { useDatabase, useFirestoreRef } from '../hooks/firebase';
import { useParams, Redirect, useHistory } from 'react-router';
import { Box, makeStyles, Button } from '@material-ui/core';
import { LoadingPage } from '../elements/loading';
import { useUserPosts } from '../hooks/post';
import { Post, PostInput } from '../elements/posts';
import { useCurrentUser, useUserInfo, useUserFollowed, followUser, unfollowUser, useUserIdInfo, useUserFollowersCount } from '../hooks/user';
import { PostsView } from '../elements/posts_view';
import { ScrollContainer, ScrollHeader } from '../elements/scroller';
import { config } from '../config';
import { RequireLoginButton } from '../elements/require';
import { Link } from 'react-router-dom';
import firebase from 'firebase';

import {Helmet} from "react-helmet";

const useStyles = makeStyles({
    space_info: {
        height: '100%',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)',
        backgroundColor: '#fff',
        border: '1px solid #fafafa'
    },
})

function usePost(pid) {
    const ref = useMemo(() => firebase.firestore().doc(`/post/${pid}`), [pid]);
    const post =  useFirestoreRef(ref);
    return useMemo(() => post ? {
        id: post.id,
        public: post.public,
        space: post.owner,
        created: post.createdAt?.toDate() ?? Date.now(),
        updated: post.updatedAt?.toDate() ?? Date.now(),
        published: post.publishedAt?.toDate(),
        text: post.text,
    } : null, [post]);
}
export function PostPage() {
    const {post: pid} = useParams()
    const post = usePost(pid);
    const user = useUserIdInfo(post?.space);

    if (false === post) {
        return <Redirect to='/404'/>
    }

    return (
        <ScrollContainer>
            { post && user ?
                <Helmet>
                    <title>Insight by {user.name}</title>
                    <meta property="og:title" content={`Insight by ${user.name}`} />
                    <meta property="og:type" content={`Insight by ${user.name}`} />
                    <meta name="description" content={post.text} />
                    <meta property="og:title" content={post.text} />
                    <meta property="og:image" content={user.image} />
                    <meta name="author" content={user.name} />
                </Helmet>
            : null}
            { post ?
                <Post post={post} owned={false} full={true}/>
            : null}
        </ScrollContainer>
    )
}