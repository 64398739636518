import React, { useState, useEffect, useCallback } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

export function useDialog() {
    const [dialog, setDialog] = useState(null)
    const [title, setTitle] = useState(null)
    const [content, setContent] = useState(null)
    const [buttons, setButtons] = useState(null)
    const [open, setOpen] = useState(false)

    const show = useCallback((title, content, buttons) => {
        const close = () => {
            setOpen(false)
        }

        setTitle(title)
        setContent(content)
        setButtons(buttons(close))
        setOpen(true)
    }, [])

    useEffect(() => {
        setDialog(
            <Dialog 
                open={open} 
                onClose={e=>setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {buttons}
                </DialogActions>
            </Dialog>
        )
    }, [open, title, content, buttons])

    return [dialog, show]
}