export let config = {
    header: {
        standard_height: 50,
    },
    posts: {
        fetch: {
            amount: 25,
            trigger_offset: 15,
        },
    },
    colors: {
        public: '#00e327',
        follow: '#00e327',
        unfollow: 'darkslategray',
    },
    search: {
        host: 'search.chita.space',
        port: 9200,
        version: '7.x',
    },
    messaging: {
        vapidKey: 'BC1nVkwrSmeV1_YlM449y4IpUJ8s5H1QLtubwNt45LhSB4N8cVU6zrfaPCBEi3sHmvn0EpHryAlTBwsBbfn_e80',
        serviceWorker: null,
    },
}