import React, { useCallback, useContext, useEffect, useState } from 'react'
import NotificationsIcon from '@material-ui/icons/Notifications';
import AddIcon from '@material-ui/icons/Add';

import { Button, makeStyles } from '@material-ui/core';
import { useDialog } from '../hooks/dialog';
import { useCookies } from 'react-cookie';
import { config } from '../config';
import { updateNotificationToken } from '../hooks/user';

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";

let messaging = null
try {
    messaging = firebase.messaging()
} catch (error) {
}

const NotificationContext = React.createContext({})


function isNotificationSupported() {
    return ('Notification' in window) && messaging
}

function useNotificationsIsActive() {
    const [active, setActive] = useState(null)
    const [cookies] = useCookies(['deviceNID'])

    useEffect(() => {
        function isActive() {
            if (!isNotificationSupported()) return false;
            if (Notification.permission !== 'granted') return false;
            if (!cookies['deviceNID']) return false;
            return true;
        }

        setActive(isActive())
    }, [cookies])

    return active
}

export function NotificationProvider({children}) {
    const active = useNotificationsIsActive()
    const [dialog, showDialog] = useDialog()
    const [cookies, setCookie] = useCookies(['deviceNID'])

    const activate = useCallback(() => {
        if (!isNotificationSupported()) return; 

        const handleGranted = () => {
            messaging.getToken({
                vapidKey: config.messaging.vapidKey,
            })
            .then(currentToken => {
                updateNotificationToken(currentToken, cookies['deviceNID'])
                .then(ref => {
                    if (!cookies['deviceNID']) {
                        setCookie('deviceNID', ref.id)
                    }
                })
            })
        }

        const handleApproval = close => {
            close()
            Notification.requestPermission()
            .then(permission => {
                if (permission === 'granted') {
                    handleGranted()
                }
            })
        }

        if (Notification.permission === 'granted') {
            handleGranted()
        }
        else if (Notification.permission !== 'denied') {
            showDialog(
                'Allow Notifications',
                'In order to receive notifications, you need to provide your approval.',
                close => [
                    <Button onClick={e => handleApproval(close)} color='secondary' variant='contained'>Approve</Button>,
                    <Button onClick={close}>Cancel</Button>
                ]
            )
        }
        else {
            showDialog(
                'Notification Access Denied',
                'You denied notifications for this app, please change this in your settings.',
                close => [
                    <Button onClick={close}>Ok</Button>
                ]
            )
        }
    }, [showDialog, setCookie, cookies])

    const deactivate = useCallback(() => {
    }, [])

    return (
        <NotificationContext.Provider value={{active, activate, deactivate}}>
            {children}
            {dialog}
        </NotificationContext.Provider>
    )
}
export function NotificationButton() {
    const styles = useStyles()
    const {active, activate, deactivate} = useContext(NotificationContext)

    return (
        <div className={styles.button_wrap}>
            {isNotificationSupported() ?
            <>
                <NotificationsIcon/>
                {active? 
                    <NotificationSign onClick={deactivate}/>
                : 
                    <NotificationAddSign onClick={activate}/>
                }
            </>
            : null}
        </div>
    )
}

function NotificationAddSign(props) {
    const styles = useStyles()
    return (
        <AddIcon {...props} className={styles.addSign}/>
    )
}

function NotificationSign(props) {
    const styles = useStyles()
    return (
        <div {...props} className={styles.sign}/>
    )
}


const useStyles = makeStyles({
    button_wrap: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '3em',
    },
    sign: {
        borderRadius: '50%',
        backgroundColor: 'lime',
        border: '2px solid #fafafa',
        width: '.4em',
        height: '.4em',

        position: 'absolute',
        left: '50%',
    },
    addSign: {
        borderRadius: '50%',
        backgroundColor: '#ffcc2a',
        border: '2px solid #fafafa',
        width: '.4em',
        height: '.4em',

        position: 'absolute',
        left: '50%',
    },

})