import React, { createContext, useState, useContext } from 'react'
import { Box, makeStyles, InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles({
    search: {
        position: 'relative',
        margin: '0 1em',
        height: '2.4em',
        backgroundColor: '#f5f5f5',
        borderRadius: '2em',
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        maxWidth: '15em',
        transition: 'width 500ms ease-out',
    },
    searchIcon: {
        position: 'absolute',
        zIndex: 1,
        left: '.5em',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#c3c3c3',
    },
    searchInputRoot: {
        width: '100%',
    },
    searchInputInput: {
        textIndent: '2.5em',
    },
})

export const SearchContext = createContext()

export function SearchProvider({children}) {
    const [term, setTerm] = useState('')
    return (
        <SearchContext.Provider value={[term, setTerm]}>
            {children}
        </SearchContext.Provider>
    )
}

export function SearchInput({placeholder}) {
    const styles = useStyles()
    const [focused, setFocused] = useState(false)
    const [term, setTerm] = useContext(SearchContext)

    return (
        <Box className={styles.search} style={focused? {maxWidth: '20em'} : null}>
            <div className={styles.searchIcon}>
                <SearchIcon/>
            </div>
            <InputBase
                placeholder={placeholder}
                classes={{
                    root: styles.searchInputRoot,
                    input: styles.searchInputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={e => setTerm(e.target.value)}
                value={term}
            />
        </Box>
    )
}
SearchInput.defaultProps = {
    placeholder: 'Search...'
}