import React from 'react'
import { Box, Button, Icon } from '@material-ui/core';
import { useCurrentUser, logOut, loginWithGoogle } from './../hooks/user';
import { usePopover } from './../hooks/popover';
import { Link } from 'react-router-dom';
import google from '../res/google.svg';
import { IntegrationButton } from '../pages/integrate';
import { useCookies } from 'react-cookie';

export function UserMenu() {
    const me = useCurrentUser()
    const [popover, show] = usePopover()
    const [cookies, createCookie, deleteCookie] = useCookies(['deviceNID'])

    const logout = e => {
        deleteCookie('deviceNID')
        logOut()
    }

    const handleClick = (e) => {
        show(e.target,
            <Box padding='1em' minWidth='10em'>
                <center>
                    <Box fontSize='.9em' fontWeight='200'>Welcome back</Box>
                    <Box fontSize='1em'>{me.displayName}</Box>
                </center>
                <hr/>
                <Link to='/myspace'>
                    <Button fullWidth size='large'>My Space</Button>
                </Link>
                <IntegrationButton fullWidth size='medium'/>
                <Button fullWidth size='large' onClick={logout}>Logout</Button>
            </Box>
        )
    }

    if (!me) {
        return (
            <Button
                onClick={loginWithGoogle}
                startIcon={
                    <Icon style={{lineHeight: '100%'}}>
                        <img height='100%' src={google} alt='Login with Google'/>
                    </Icon>
                }
            >
                Login
            </Button>
        )
    }

    return (
        <>
            <Box
                component='img'
                height='2em'
                src={me.photoURL}
                borderRadius='50%'
                border='5px solid #f9f9f9'
                onClick={handleClick}
            />
            {popover}
        </>
    )
}