import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/functions';
import { config } from '../config';

export interface IDatabasePost {
    id?: string; // only on search
    public: boolean;
    owner: string;
    createdAt: Date | firebase.firestore.FieldValue | string;
    updatedAt: Date | firebase.firestore.FieldValue | string;
    publishedAt?: Date | firebase.firestore.FieldValue | string;
    text: string;
}

export interface IPost {
    id: string;
    public: boolean;
    space: string;
    created: Date;
    updated: Date;
    published: Date;
    text: string;
}

export async function createPost(uid: string, text: string) {
    const post: IDatabasePost = {
        public: false,
        owner: uid,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        text,
    };

    const result = await firebase.firestore().collection('post').add(post);

    firebase.analytics().logEvent('post_create', { post_id: result.id, });

    return result;
}

export async function deletePost(post: IPost) {
    const result = await firebase.firestore().collection('post').doc(post.id).delete();

    firebase.analytics().logEvent('post_delete', {
        post_id: post.id,
    });

    return result;
}

export async function editPost(post: IPost, newText: string) {
    const result = await firebase.firestore()
        .collection('post')
        .doc(post.id)
        .update({
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            text: newText,
        });

    firebase.analytics().logEvent('post_edit', {
        post_id:  post.id,
    });

    return result;
}

export function usePosts(reference: firebase.firestore.Query | null) {
    const basic_limit = config.posts.fetch.amount
    const [noMore, setNoMore] = useState(false)
    const [limit, setLimit] = useState(basic_limit)
    const [posts, setPosts] = useState<IPost[] | null>(null)

    useEffect(() => {
        if (reference === null) return;

        const unsubscribe = reference
        .limitToLast(limit)
        .onSnapshot((snapshot) => {
            const newPosts: IPost[] = snapshot.docs.map((doc) => ({
                id: doc.id,
                public: doc.data().public,
                space: doc.data().owner,
                created: doc.data().createdAt?.toDate() ?? Date.now(),
                updated: doc.data().updatedAt?.toDate() ?? Date.now(),
                published: doc.data().publishedAt?.toDate(),
                text: doc.data().text,
            })).reverse();

            setPosts(newPosts);
            setNoMore(newPosts.length < limit);
        })

        return () => {
            unsubscribe();
        }
    }, [reference, limit])

    const requestMore = useCallback(() => {
        if (noMore) {
            return false;
        }

        setLimit(limit => limit + basic_limit)
        return true;
    }, [noMore, basic_limit])

    return useMemo(() => ({posts, requestMore, noMore}), [posts, requestMore, noMore]);
}

export function useUserPosts(uid: string, owner: boolean) {
    const reference = useMemo(() => {
        if (!uid) {
            return null;
        }

        if (!owner) {
            return firebase.firestore()
                .collection('post')
                .where('owner', '==', uid)
                .where('public', '==', true)
                .orderBy('createdAt')
        }

        return firebase.firestore()
            .collection('post')
            .where('owner', '==', uid)
            .orderBy('createdAt')
    }, [uid, owner]);

    return usePosts(reference);
}

export function usePublicPostsElastic(uid: string) {
    throw new Error('NOT IMPLEMENTED');
    /*
    const [query, setQuery] = useState(null)

    useEffect(() => {
        if (!uid) return;
        setQuery({
            sort: { published: "desc" },
            query: {
                match: {
                    space: {
                        query: uid,
                    },
                },
            },
        })
    }, [uid])

    return usePostsElastic(query)
    */
}

export function useExplorePosts() {
    const reference = useMemo(() => (
        firebase.firestore()
        .collection('post')
        .where('public', '==', true)
        .orderBy('createdAt')
    ), []);

    return usePosts(reference);
}

export function useRandomPosts() {
    const basic_limit = config.posts.fetch.amount
    const [noMore, setNoMore] = useState(false)
    const [limit, setLimit] = useState(basic_limit)
    const [posts, setPosts] = useState<IPost[] | null>(null)
    const seed = useMemo(() => Math.floor(Math.random() * 999999999999),[]);

    const postsRef = useRef<IPost[]>([]);
    postsRef.current = posts ?? [];

    useEffect(() => {
        let cancel = false;
        const getRandomPosts = firebase.functions().httpsCallable('randomPosts');

        const size = limit - postsRef.current.length;
        getRandomPosts({
            seed,
            size,
            from: postsRef.current?.length,
        }).then((result) => {
            if (cancel) return;

            const hits = result.data as IDatabasePost[];
            console.log(hits);
            const newPosts: IPost[] = hits.map((hit) => ({
                id: hit.id ?? '',
                public: hit.public,
                space: hit.owner,
                created: new Date(hit.createdAt as string),
                updated: new Date(hit.updatedAt as string),
                published: new Date(hit.publishedAt as string),
                text: hit.text,
            })).reverse();

            setPosts(old => [...(old ?? []), ...newPosts]);
            setNoMore(newPosts.length < size);
        });


        return () => {
            cancel = true;
        }
    }, [basic_limit, limit, seed])

    const requestMore = useCallback(() => {
        if (noMore) {
            return false;
        }

        setLimit(limit => limit + basic_limit)
        return true;
    }, [noMore, basic_limit])

    return useMemo(() => ({posts, requestMore, noMore}), [posts, requestMore, noMore]);
}

export function useFollowingPosts(followings: string[]) {
    throw new Error('NOT IMPLEMENTED');
    /*
    const [query, setQuery] = useState(null)

    useEffect(() => {
        if (!followings) return;
        if (followings.length === 0) return;

        setQuery({
            sort: { published: "desc" },
            query: {
                bool: {
                    filter: {
                        terms: {
                            'space.keyword': followings,
                        },
                    },
                },
            },
        })
    }, [followings])

    return usePostsElastic(query)
    */
}

export async function privateToPublic(post: IPost) {
    const result = await firebase.firestore()
        .collection('post')
        .doc(post.id)
        .update({
            public: true,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            publishedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

    firebase.analytics().logEvent('post_publish', {
        post_id: post.id,
    });

    return result;
}


export async function publicToPrivate(post: IPost) {
    const result = await firebase.firestore()
        .collection('post')
        .doc(post.id)
        .update({
            public: false,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

    firebase.analytics().logEvent('post_hide', {
        post_id: post.id,
    });

    return result;
}
