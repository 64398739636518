import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect, Link } from 'react-router-dom';
import './firebase/init';
import { FeedPage } from './pages/feed';
import { ExplorePage, ExploreRandomPage } from './pages/explore';
import { SearchPage } from './pages/search';
import { GenericPage } from './chitalib/page';
import { SiteMenu } from './pages/menu';
import logo from './res/logo.svg'
import { ThemeProvider, createMuiTheme, Box, makeStyles } from '@material-ui/core';
import { SpacePage, MySpacePage } from './pages/space';
import { UserMenu } from './elements/usermenu';
import { SearchProvider, SearchInput } from './elements/search';
import smoothscroll from 'smoothscroll-polyfill';
import { IntegrationPage } from './pages/integrate';
import { NotificationProvider } from './elements/notification_button';
import { PostPage } from './pages/post.page';
import { PrivacyPage } from './pages/privacy';
 
// kick off the polyfill!
smoothscroll.polyfill();

const lightTheme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'Rubik', 'Roboto'],
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#2f4f4f',
    },
    secondary: {
      main: '#fff000',
      contrastText: 'darkslategray',
    },
    text: {
      primary: '#000',
      secondary: '#162447',
    },
  },
  shape: {
    borderRadius: 2,
  },
  shadows: ['none']
})

const useStyles = makeStyles({
  app: {
    position: 'fixed',
    width: '100vw',
    left: 0,
    top: 0,
  }
})

function App() {
  const styles = useStyles()
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    function onResize(e) {
      setHeight(e.target.innerHeight)
    }
    window.addEventListener("orientationchange", onResize)
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('orientationchange', onResize)
      window.removeEventListener('resize', onResize)
    }
  })


  return (
    <ThemeProvider theme={lightTheme}>
    <NotificationProvider>
    <SearchProvider>
      <div className={styles.app} style={{height: height}}>
        <BrowserRouter>
          <GenericPage
            header={
              <Box display='flex' width='100%' alignItems='center'>
                <Link to='/'>
                  <Box component='img' src={logo} height='2.3em' pb='.4em'/>
                </Link>
                <Box flex='1'>
                  <SearchInput/>
                </Box>
                <UserMenu />
              </Box>
            }
            menu={<SiteMenu />}
            content={
              <>
                <SearchPage />
                <Switch>
                  <Route exact path='/space/:space'>
                    <SpacePage/>
                  </Route>
                  <Route exact path='/post/:post'>
                    <PostPage/>
                  </Route>
                  <Route exact path='/integrate'>
                    <IntegrationPage/>
                  </Route>
                  <Route exact path='/myspace'>
                    <MySpacePage/>
                  </Route>
                  <Route exact path='/feed'>
                    <FeedPage/>
                  </Route>
                  <Route exact path='/explore'>
                    <ExplorePage/>
                  </Route>
                  <Route exact path='/random'>
                    <ExploreRandomPage/>
                  </Route>
                  <Route exact path='/privacy'>
                    <PrivacyPage/>
                  </Route>
                  <Route path='*'>
                    <Redirect to='/random' />
                  </Route>
                </Switch>
              </>
            }
          />
        </BrowserRouter>
      </div>
    </SearchProvider>
    </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;
