import { useState, useEffect } from 'react'
import firebase, { firestore } from 'firebase/app';
import 'firebase/database';

export function useDatabase(path) {
    const [ref, setRef] = useState(null)

    useEffect(() => {
        if (path) {
            setRef(
                firebase.database().ref(path)
            )
        }

        return () => setRef(null)
    }, [path])

    return useDatabaseRef(ref)
}

export function useDatabaseRef(reference) {
    const [data, setData] = useState(null)

    useEffect(() => {
        if (!reference) return; 
        let cancel = false

        reference.on('value', (snapshot) => {
            if (cancel) return;

            const data = snapshot.val()
            if (data) {
                setData(data)
            }
            else {
                setData(false)
            }
        })

        return () => {
            cancel = true
            setData(null)
        }
    }, [reference])

    return data
}

function firestoreDefaultParseResponse(response) {
    if (response.query) {
        return response.docs.map(doc => doc.ref)
    }

    return response
}

function firestoreDefaultParseDocument(document) {
    return document.data()
}

export function useFirestoreRef(reference, parse_doc=firestoreDefaultParseDocument) {
    const [data, setData] = useState(null)

    useEffect(() => {
        if (!reference) return; 
        let cancel = false

        reference.onSnapshot((snapshot) => {
            if (cancel) return;
            const data = firestoreDefaultParseResponse(snapshot)
            if (data) {
                if (data.map) {
                    setData(data.map(doc => parse_doc(doc)))
                } else {
                    setData(parse_doc(data))
                }
            }
            else {
                setData(false)
            }
        })

        return () => {
            cancel = true
            setData(null)
        }
    }, [reference, parse_doc])

    return data
}