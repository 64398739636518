import React, { useState, useEffect } from 'react'
import { UserRestrictedPage } from '../elements/pages'
import { Box, makeStyles, Button } from '@material-ui/core';
import { ScrollContainer, ScrollHeader } from '../elements/scroller';
import { PostsView } from '../elements/posts_view';
import { useCurrentUser, useUserFollowing } from '../hooks/user';
import { LoadingPage } from '../elements/loading';
import { Link } from 'react-router-dom';
import { useFollowingPosts, usePublicPostsElastic } from '../hooks/post';
import { NotificationButton } from '../elements/notification_button';

const useStyles = makeStyles({
    header: {
        height: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #fafafa',
        textIndent: '1em',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)',
    },
    title: {
        fontSize: '1.2em',
        fontWeight: 200,
    },
    title_title: {
        display: 'contents',
        fontWeight: 400,
    }
})

export function FeedPage() {
    const styles = useStyles()
    const me = useCurrentUser()
    const following = useUserFollowing(me? me.uid : null)
    const posts = useFollowingPosts(following)
    const my_posts = usePublicPostsElastic(me? me.uid : null)

    return (
        <UserRestrictedPage>
            <ScrollContainer key={'feed'}>
                <ScrollHeader min_height={45} max_height={45}>
                    <Box className={styles.header}> 
                        <Box className={styles.title}>
                            <Box className={styles.title_title}>
                                Feed
                            </Box>
                            &nbsp;from people you follow
                        </Box>
                        <Box flex={1}/>
                        <Box><NotificationButton/></Box>
                    </Box>
                </ScrollHeader>
                { following === null ?
                    <LoadingPage />
                : following.length === 0 ?
                    <>
                        <Box my='1em'>You haven't followed anyone yet.</Box>
                        <Link to='/explore'>
                            <Button size='large'>
                                Explore
                            </Button>
                        </Link>
                    </>
                :
                    <PostsView 
                        key={'feed-posts'}
                        post_pools={[posts, my_posts]}
                        full={true}
                        sort={(a,b) => b.published - a.published}
                    />
                }
            </ScrollContainer>
        </UserRestrictedPage>
    )
}