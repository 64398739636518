import React, { useState, useEffect } from 'react'
import { Popover } from '@material-ui/core'

export function usePopover() {
    const [popover, setPopover] = useState(null)
    const [component, setComponent] = useState(null)
    const [anchorElement, setAnchorElement] = useState(null)
    const [open, setOpen] = useState(false)

    const pop = (anchor, component) => {
        setAnchorElement(anchor)
        setComponent(component)
        setOpen(true)
    }

    useEffect(() => {
        setPopover(
            <Popover
                open={open}
                anchorEl={anchorElement}
                onClose={e => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClick={e => setOpen(false)}
            >
                {component}
            </Popover>
        )
    }, [anchorElement, component, open])

    return [popover, pop]
}

