import { config } from './../config'
import { Client } from 'elasticsearch'
import { useState, useEffect, useCallback } from 'react'

const elastic = new Client({
    host: `https://${config.search.host}:${config.search.port}`,
    apiVersion: config.search.version,
})

function defaultFormatter(hit) {
    return hit._source
}

function formatElasticQueryResults(result, formatter) {
    const hits = result.hits.hits
    const items = []

    for (let hit of hits) {
        const post = formatter(hit)
        items.push(post)
    }

    return items
}

export function useElasticQuery(index, request, formatter=defaultFormatter, is_public=true) {
    const [from, setFrom] = useState(0)
    const [results, setResults] = useState(null)
    const [noMore, setNoMore] = useState(false)

    useEffect(() => {
        setResults(null)
        setNoMore(false)
        setFrom(0)
    }, [request])

    useEffect(() => {
        if (!request) { return }

        let is_canceled = false

        const basic_limit = config.posts.fetch.amount

        elastic.search({
            index: index,
            from: from,
            size: basic_limit,
            body: request,
        })
        .then((results) => {
            if (is_canceled) return;

            const items = formatElasticQueryResults(results, formatter)
            if (items.length < config.posts.fetch.amount) {
                setNoMore(true)
            }
            setResults(old => old === null? items : old.concat(items))
        })

        return () => {
            is_canceled = true
        }

    }, [index, from, request, formatter])

    const requestMore = () => {
        if (noMore) {
            return false
        }

        setFrom(old => old + config.posts.fetch.amount)
        return true;
    }

    return {items: results, requestMore, noMore}
}

export function usePostsElastic(request, is_public=true) {
    const formatter = useCallback(hit => {
        const post = hit._source
        post['id'] = hit._id
        post['public'] = is_public
        return post
    }, [is_public])

    const {items, requestMore, noMore} = useElasticQuery('posts', request, formatter)
    return {posts: items, requestMore, noMore}
}

export function useUsersElastic(request) {
    const formatter = useCallback(hit => {
        const user = hit._source
        user['id'] = hit._id
        user['is_old'] = hit._id.startsWith('FBUID')
        return user
    }, [])

    const {items, requestMore, noMore} = useElasticQuery('users', request, formatter)
    return {users: items, requestMore, noMore}
}