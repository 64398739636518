import { useState, useEffect, useCallback } from 'react'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/analytics';

import { useDatabase, useFirestoreRef } from './firebase';

export function useCurrentUser() {
    const [user, setUser] = useState(firebase.auth().currentUser)

    useEffect(() => {
        let cancel = false

        firebase.auth().onAuthStateChanged((user) => {
            if (cancel) return

            if (!user) {
                user = false
            }
            setUser(user)
        })

        return () => cancel = true
    }, [])

    useEffect(() => {
        if (user) {
            setupMyInfo(user)
        }
    }, [user])

    return user
}

export function logOut() {
    return firebase.auth().signOut()
}
export function loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider)
}

export function unlinkWithFacebook() {
    firebase.auth().currentUser.unlink('facebook.com')
}

export function setupFacebookUid() {
    function isAlreadyLinked() {
        const me = firebase.auth().currentUser
        if (!me) return false;
        if (me.providerData.length <= 1) return false;
        if (getFacebookUid() === null) return false;
        return true;
    }

    function getFacebookUid() {
        const me = firebase.auth().currentUser
        for (const provider of me.providerData) {
            if (provider.providerId === 'facebook.com') {
                return provider.uid
            }
        }

        return null
    }

    if (isAlreadyLinked()) {
        const me = firebase.auth().currentUser
        return firebase.database()
        .ref(`/users/${me.uid}/facebook_uid`)
        .set(getFacebookUid())
        .then(unlinkWithFacebook)
    }
}

export function linkWithFacebook() {
        const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().currentUser.linkWithPopup(provider)
}

export function useUserInfo(user) {
    const [uid, setUid] = useState(null)

    useEffect(() => {
        if (user) {
            setUid(user.uid)
        }

        return () => setUid(null)
    }, [user])

    return useUserIdInfo(uid)
}

export function useUserIdInfo(uid) {
    const [path, setPath] = useState(null)

    useEffect(() => {
        if (uid) {
            setPath(`/users/${uid}/public`)
        }

        return () => setPath(null)
    }, [uid])

    return useDatabase(path)
}

export function useUserFollowedOld(user, follow_uid) {
    const [path, setPath] = useState(null)

    useEffect(() => {
        if (user && follow_uid) {
            setPath(`/users/${user.uid}/following/${follow_uid}`)
        }

        return () => setPath(null)
    }, [user, follow_uid])

    return useDatabase(path)
}

export function useUserFollowed(user, follow_uid) {
    const [ref, setRef] = useState(null)

    useEffect(() => {
        if (user && follow_uid) {
            setRef(
                firebase.firestore()
                .doc(`user_follows/${follow_uid}/followers/${user.uid}`)
            )
        }

        return () => setRef(null)
    }, [user, follow_uid])

    return useFirestoreRef(ref)
}

export function useUserFollowing(uid) {
    const [ref, setRef] = useState(null)
    const parser = useCallback(doc => {
        return doc.parent.parent.id
    },[])

    useEffect(() => {
        if (uid) {
            setRef(
                firebase.firestore()
                .collectionGroup('followers')
                .where('user', '==', uid)
            )
        }

        return () => setRef(null)
    }, [uid])

    return useFirestoreRef(ref, parser)
}

export function useUserFollowersCount(user) {
    const [path, setPath] = useState(null)

    useEffect(() => {
        if (user) {
            setPath(`/following/${user.uid}`)
        }

        return () => setPath(null)
    }, [user])

    return useDatabase(path)
}

export function updateNotificationToken(token, deviceNID) {
    const me = firebase.auth().currentUser
    if (deviceNID) {
        return firebase.firestore()
        .collection('user_notification_tokens')
        .doc(me.uid)
        .collection('tokens')
        .doc(deviceNID)
        .set({token: token})
    }
    else {
        return firebase.firestore()
        .collection('user_notification_tokens')
        .doc(me.uid)
        .collection('tokens')
        .add({token: token})
    }
}

export function followUser(me, follow_uid) {
    firebase.analytics().logEvent('user_follow', {
        user: follow_uid,
    })

    return firebase.firestore()
    .collection('user_follows')
    .doc(follow_uid)
    .collection('followers')
    .doc(me.uid)
    .set({user: me.uid})
}

export function unfollowUser(me, unfollow_uid) {
    firebase.analytics().logEvent('user_unfollow', {
        user: unfollow_uid,
    })

    return firebase.firestore()
    .collection('user_follows')
    .doc(unfollow_uid)
    .collection('followers')
    .doc(me.uid)
    .delete()
}

var g_setup_info_started = false
export function setupMyInfo(me) {
    if (!me) return;
    if (me.uid === g_setup_info_started) return;
    g_setup_info_started = me.uid

    const setupAll = () => {
        setupMyNick(me)
        imageToBase64(me.photoURL, image => {
            firebase.database().ref(`/users/${me.uid}/public`)
            .set({
                name: me.displayName,
                image: image,
                created: firebase.database.ServerValue.TIMESTAMP,
            })
        })
    }

    const updateImage = (image) => {
        imageToBase64(me.photoURL, new_image => {
            if (new_image !== image) {
                console.log("updating image...")
                firebase.database().ref(`/users/${me.uid}/public/image`)
                .set(new_image)
            }
        })
    }

    const updateName = (name) => {
        if (name !== me.displayName) {
            console.log("updating name...")
            firebase.database().ref(`/users/${me.uid}/public/name`)
            .set(me.displayName)
        }
    }

    firebase.database().ref(`/users/${me.uid}/public`)
    .once('value').then((snapshot) => {
        const data = snapshot.val()

        if (!data) {
            setupAll()
        }
        else {
            updateImage(data.image)
            updateName(data.name)
            if (!data.nick) {
                setupMyNick(me)
            }
        }
    })
    setupFacebookUid()
}

var g_nick_setup_started = false;
export function setupMyNick(me) {
    if (!me) return;
    if (g_nick_setup_started === me.uid) return;
    g_nick_setup_started = me.uid;

    const nick = me.email.replace(/@\S+/, '').replace('.', '%')
    const setupNick = (nick) => {
        changeNick(me, nick)
        .catch((error) => {
            if (error === 'Nick already taken.') {
                setupNick(nick + Math.floor(Math.random() * 10).toString())
            }
        })
    }
    setupNick(nick)
}

export function validateNick(nick, uid) {
    return new Promise((resolve, reject) => {
        if (!nick.match(/^[a-z,0-9,%]+$/)) {
            reject('Nick contains invalid characters.')
            return
        }
        firebase.database().ref(`/nicks/${nick}`).once('value', snapshot => {
            const current_uid = snapshot.val()
            if (current_uid && current_uid !== uid) {
                console.log('Nick already taken!')
                reject('Nick already taken.')
                return
            }
            else {
                resolve()
                return
            }
        }).catch(reject)
    })
}

export function changeNick(me, nick) {
    const setNick = (resolve, reject) => {
        firebase.database().ref(`/users/${me.uid}/public/nick`)
        .set(nick)
        .then(() => {
            firebase.database().ref(`/nicks/${nick}`)
            .set(me.uid)
            .catch(reject)
            .then(resolve)
        })
        .catch(reject)
    }

    return new Promise((resolve, reject) => {
        if (!me) {
            reject('User must be supplied')
        }
        validateNick(nick, me.uid)
        .then(() => {
            firebase.database().ref(`/users/${me.uid}/public/nick`)
            .once('value', (snapshot) => {
                const old_nick = snapshot.val()
                if (old_nick) {
                    firebase.database().ref(`/nicks/${old_nick}`).remove()
                    .catch(() => console.log('could not remove old nick..'))
                    .finally(() => {
                        setNick(resolve, reject)
                    })
                }
                else {
                    setNick(resolve, reject)
                }
            })
        })
        .catch(reject)
    })
}

function imageToBase64(url, callback) {
    url = url + '=s75' /* Retrieve smaller photo */
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}
  