import React, { createContext, useState, useEffect, useContext, useCallback } from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    supercontainer: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        padding: '0 1em',
    },
    container: {
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    header_static: {
        width: '100%',
    },
    header_dynamic: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 2,
    }
})
const ScrollContext = createContext();

export function ScrollContainer({children}) {
    const [context, setContext] = useState(null)
    const styles = useStyles()

    return (
        <Box className={styles.supercontainer}>
            <Box className={styles.container} ref={e => setContext(e)}>
                <ScrollContext.Provider value={context}>
                    {children}
                </ScrollContext.Provider>
            </Box>
        </Box>
    )
}

export function useScrollTo() {
    const context = useContext(ScrollContext)

    const scrollTo = useCallback((element, offset) => {
        if (offset === undefined) {
            offset = 0
        }
        context.scrollTo({top: element.offsetTop + offset, behavior: 'smooth'})
    }, [context])

    return scrollTo
}

export function ScrollHeader({children, min_height, max_height}) {
    const context = useContext(ScrollContext)
    const [height, setHeight] = useState(max_height)
    const styles = useStyles()

    useEffect(() => {
        if (!context) return;
        
        const calculateHeight = (top) => {
            top = top < 0 ? 0 : top
            const tmp_height = max_height - top
            if (tmp_height < min_height)
                return min_height
            return tmp_height
        }

        const handleScroll = (e) => {
            const element = e.target
            setHeight(calculateHeight(element.scrollTop))
        }

        context.addEventListener('scroll', handleScroll)
        return () => context.removeEventListener('scroll', handleScroll)
    }, [context, min_height, max_height])

    return (
        <>
            <Box className={styles.header_static} height={max_height}/>
            <Box className={styles.header_dynamic} height={height}>
                {children}
            </Box>
        </>
    )
}
ScrollHeader.defaultProps = {
    min_height: 20,
    max_height: 50,
}