import React, { useState, useEffect, useMemo } from 'react'
import { useDatabase } from './../hooks/firebase';
import { useParams, Redirect, useHistory } from 'react-router';
import { Box, makeStyles, Button } from '@material-ui/core';
import { LoadingPage } from '../elements/loading';
import { useUserPosts } from './../hooks/post';
import { PostInput } from './../elements/posts';
import { useCurrentUser, useUserInfo, useUserFollowed, followUser, unfollowUser, useUserIdInfo, useUserFollowersCount } from './../hooks/user';
import { PostsView } from '../elements/posts_view';
import { ScrollContainer, ScrollHeader } from '../elements/scroller';
import { config } from '../config';
import { RequireLoginButton } from '../elements/require';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    space_info: {
        height: '100%',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)',
        backgroundColor: '#fff',
        border: '1px solid #fafafa'
    },
    follow: {
        padding: '0.2em .5em',
        margin: '0 0.5em',
        fontWeight: 800,
        color: config.colors.follow,
        borderBottom: `1px solid ${config.colors.follow}`,
    },
    unfollow: {
        padding: '0.2em .5em',
        margin: '0 0.5em',
        fontWeight: 400,
        color: config.colors.unfollow,
    },
    writeButton: {
        backgroundColor: '#fff000',
        padding: '.5em .5em',
        color: '#e0e0e0e',
        margin: '0 .25rem',
        fontFamily: 'inherit',
        fontWeight: 400,
        letterSpacing: '.5px',
        borderRadius: '2px',
    }
})

export function MySpacePage() {
    const me = useCurrentUser()
    const info = useUserInfo(me)

    if (me === false) {
        return <Redirect to='/404'/>
    }

    if (me === null || info === null || !info.nick) {
        return <LoadingPage/>
    }

    return <Redirect to={`/space/${info.nick.replace('%', '.')}`} />
}

export function SpacePage() {
    const {space: nick} = useParams()
    const uid = useDatabase(`/nicks/${nick}`.replace('.', '%'))
    const user = useUserIdInfo(uid)

    if (false === user || false === uid) {
        return <Redirect to='/404'/>
    }

    return (
        <ScrollContainer key={nick}>
            <ScrollHeader max_height={100} min_height={config.header.standard_height}>
                <SpaceInfoSection uid={uid} user={user}/>
            </ScrollHeader>
            <PostInput uid={uid}/>
            <SpacePosts uid={uid}/>
        </ScrollContainer>
    )
}

function SpaceInfoSection({uid, user}) {
    const styles = useStyles()
    return (
        <Box className={styles.space_info}>
            {user ?
                <Box display='flex' height='100%' alignItems='center'>
                    <Box height='100%' display='contents'>
                        <Box
                            component='img'
                            src={user.image}
                            style={{objectFit: 'cover', height: '70%'}}
                            borderRadius='50%'
                            border='5px solid #f9f9f9'
                        />
                    </Box>
                    <Box marginLeft='1em' fontSize='1.2em' fontWeight={400}>
                        {user.name}<Box component='span' fontWeight={100}>'s insights</Box>
                    </Box>
                    <Box flex={1}/>
                    <SpaceFollowButton uid={uid}/>
                </Box>
            :
                <LoadingPage/>
            }
        </Box>
    )
}

function SpacePosts({uid}) {
    const me = useCurrentUser()
    const [owner, setOwner] = useState(false)
    const posts = useUserPosts(uid, owner)
    const pools = useMemo(() => [posts], [posts]);

    useEffect(() => {
        if (me && me.uid === uid) {
            setOwner(me.uid)
        }

        return () => setOwner(false)
    }, [me, uid])
    
    return (
        <PostsView 
            post_pools={pools}
            owned={owner? true : false}
            sort={owner ?
                (a, b) => b.created - a.created
            :
                (a, b) => b.published - a.published
            }
            empty_content={ owner ?
                <p>You haven't written anything yet...<br/>
                <Link to='/integrate'>Do you own an old (facebook) chita.space account?</Link>
                </p>
                :
                <p>There are no public posts in this space.</p>
            }
        />)
}

function SpaceFollowButton({uid}) {
    const me = useCurrentUser()
    const isFollowing = useUserFollowed(me, uid)
    const styles = useStyles()
    const followersCount = useUserFollowersCount(me)

    const handleFollow = (e, logged_in_me) => {
        if (logged_in_me && logged_in_me.uid !== uid) {
            followUser(logged_in_me, uid)
        }
    }

    const handleUnfollow = () => {
        unfollowUser(me, uid)
    }

    return (
        <>
        {
            uid && uid.startsWith('FBUID_') ? 
                null
            : !me || (me.uid !== uid && isFollowing !== null) ? (
                isFollowing? (
                    <Button className={styles.unfollow} onClick={handleUnfollow}>
                        Unfollow
                    </Button>
                ) : (
                    <RequireLoginButton
                        component={Button}
                        className={styles.follow}
                        onClick={handleFollow}
                    >
                        Follow
                    </RequireLoginButton>
                )
            ) : me && me.uid === uid && followersCount && followersCount > 0 ? (
                <Box textAlign='center' padding='1em' fontSize='1em' fontWeight={200}>
                    <Box component='span' fontWeight={400}>{followersCount}</Box> {followersCount === 1? 'follower' : 'followers'}
                </Box>
            ) : null
        }
        </>
    )
}

export function WriteSomethingButton() {
    const history = useHistory()
    const styles = useStyles()
    
    const clickHandler = () => {
        history.push('/myspace')
    }

    return (
        <>
            <RequireLoginButton
                onClick={clickHandler}
                variant='contained'
                className={styles.writeButton}
            >
                Write
            </RequireLoginButton>
        </>
    )
}