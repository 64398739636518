import React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	content: {
		overflow: 'hidden',
		boxOrient: 'vertical',
		lineClamp: 5,
	},
	read_more: {
		color: '#444444',
		lineHeight: '2em',
		fontSize: '.8em',
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		}
	}
})

export const ExpandableContent = React.memo(function({className, onResize, children}) {
	const [isExpandable, setIsExpandable] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const savedHeight = useRef(null);
    const contentElement = useRef(null);
    const readMoreElement = useRef(null);
	
	const styles = useStyles()
	/* check for expandable */
	useEffect(() => {
		const element = contentElement.current;
		setIsExpandable(element.offsetHeight < element.scrollHeight);
	}, [contentElement, setIsExpandable]);

	const getCurrentHeight = () => (readMoreElement.current ? readMoreElement.current.clientHeight : 0) + contentElement.current.clientHeight;

	/* handle resize */
	const handleResize = useCallback(() => {
		const currentHeight = getCurrentHeight();
		if (currentHeight !== savedHeight.current) {
			if (onResize) {
				onResize(currentHeight - savedHeight.current);
			}
		}
	}, [onResize]);

	/* hook resize */
	useEffect(() => {
		const observer = new MutationObserver(handleResize);
		observer.observe(contentElement.current, {childList: false, attributes: true, subtree: false});
		return () => { observer.disconnect(); };
	}, [contentElement, handleResize]);

	/* handle read more */
	const onReadMore = (e) => {
		e.stopPropagation();
		savedHeight.current = getCurrentHeight();
		setIsExpanded(true);
	};

	const style = {
		display: (isExpanded ? "block" : "-webkit-box"),
	};

	return (
		<>
			<span className={styles.content} style={style} ref={contentElement}>
				{children}
			</span>
			{ (isExpandable && !isExpanded) ? 
				<span className={styles.read_more} onClick={onReadMore} ref={readMoreElement}>
					Read More
				</span>
			: null }
		</>
	);
});