import React, { useState, useEffect } from 'react'
import { Box, makeStyles, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ScrollContainer, ScrollHeader } from '../elements/scroller';
import { config } from '../config';
import { UserRestrictedPage } from '../elements/pages';
import { linkWithFacebook, useCurrentUser, unlinkWithFacebook, logOut, loginWithGoogle } from '../hooks/user';
import { useAlert } from '../hooks/alert';
import { useDialog } from '../hooks/dialog';
import { useHistory } from 'react-router';
import { useDatabase } from './../hooks/firebase';

const useStyles = makeStyles({
    header: {
        height: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #fafafa',
        textIndent: '1em',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)',
    },
    title: {
        fontSize: '1.2em',
        fontWeight: 200,
    },
    title_title: {
        display: 'contents',
        fontWeight: 400,
    },
    fb_button: {
        backgroundColor: '#3B5998',
        color: '#fafafa',
        borderRadius: '2em',
    },
    integrate_button: {
    },
})

function useMyFacebookUid() {
    const me = useCurrentUser()
    const [path, setPath] = useState(null)

    useEffect(() => {
        if (me) {
            setPath(`/users/${me.uid}/facebook_uid`)
        }

        return () => setPath(null)
    }, [me])

    return useDatabase(path)
}

export function IntegrationButton(props) {
    const me = useCurrentUser()
    const fbuid = useMyFacebookUid()
    const styles = useStyles()
    const history = useHistory()

    const handleClick = e => {
        history.push('/integrate')
    }

    if (!me || fbuid === null || fbuid) {
        return null
    }

    return (
        <Button {...props} className={styles.integrate_button} onClick={handleClick}>
            Integrate Old Account
        </Button>
    )
}

function useUnlinkFacebook() {
    const me = useCurrentUser()

    useEffect(() => { 
        if (me) {
            /* This code should be removed, it's here only because I have a few bugged users:
            > yaniv
            > tom
            > amit chita
            When they are fixed I need to remove this */
            console.log('trying to unlink!')
            try {
                unlinkWithFacebook()
            }
            catch {}
        }
    }, [me])
    
    return null
}
export function IntegrationPage() {
    const styles = useStyles()
    const [disabled, setDisabled] = useState(false)
    const [alertElement, alert] = useAlert()
    const [dialogElement, dialog] = useDialog()
    const history = useHistory()
    useUnlinkFacebook()

    const handleFacebookLinking = e => {
        const redirect = e => {
            history.push('/myspace')
        }

        const finish = e => {
            logOut()
            .then(() => {
                loginWithGoogle()
                .finally(redirect)
            })
            .finally(redirect)
        }

        setDisabled(true)
        linkWithFacebook()
        .then((result) => {
            dialog(
                'Facebook Account Linked Successfully',
                'We successfully linked your Facebook account to your chita.space account. Our system will now automatically integrate your old content into this account. This might take a few minutes until you will see the effects.\nIn order to continue, we need you to re-login again.',
                close => <Button variant='contained' onClick={finish}>Continue</Button>
            )
        })
        .catch((error) => {
            console.log(error)
            setDisabled(false)
            alert(
                <Alert severity='error'>Could not link Facebook account.</Alert>
            )
        })
    }

    return (
        <UserRestrictedPage>
        <ScrollContainer key={'explore'}>
            <ScrollHeader 
                min_height={config.header.standard_height}
                max_height={config.header.standard_height}
            >
                <Box className={styles.header}> 
                    <Box className={styles.title}>
                        <Box className={styles.title_title}>
                            Old Account Integration
                        </Box>
                    </Box>
                </Box>
                <Box padding={'1em'}>
                    <Box component='h3'>
                        Do you own an old chita.space account?
                    </Box>
                    <Box component='p'>
                        In the past, chita.space worked with facebook login. Now we changed it to support only google. If you identified in the past using facebook, you can use this page to integrate your old data into your new google account.
                    </Box>
                    <Button 
                        className={styles.fb_button}
                        variant='contained'
                        onClick={handleFacebookLinking}
                        disabled={disabled}
                    >
                        Link Facebook Account
                    </Button>
                    {alertElement}
                    {dialogElement}
                </Box>
            </ScrollHeader>
        </ScrollContainer>
        </UserRestrictedPage>
    )
}