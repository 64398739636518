import React from 'react'
import { makeStyles, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    '@keyframes fade-in-bottom': {
        from: {
            transform: 'translateY(+15px)',
            opacity: 0,
        },
        to: {
            transform: 'translateY(0)',
            opacity: 1,
        }
    },
    user_card: {
        paddingTop: '.75em',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '4.5em',
    },
    users_view: {
        width: '100%',
        overflowX: 'scroll',
        animation: '$fade-in-bottom 0.2s ease-out',
    },
    user_name: {
        padding: '.5em .2em',
        maxWidth: '100%',
        fontSize: '.6em',
        display: 'inline-block',
        textAlign: 'center',
    }
})

export function UsersView({users}) {
    const styles = useStyles()

    return ( users ?
        <Box className={styles.users_view}>
            {users?
                users.map(user => (
                    <UserView user={user} key={user.id}/>
                ))
            : null}
        </Box>
        : null
    )
}

export function UserView({user}) {
    const styles = useStyles()

    return (
        <Link to={user && user.nick? `/space/${user.nick.replace('%', '.')}` : ''}>
            <Box className={styles.user_card}>
                <UserImage user={user}/>
                <UserName user={user}/>
            </Box>
        </Link>
    )
}

export function UserImage({user}) {
    return (
        <Box
            src={user? user.image : null}
            style={{
                backgroundColor: '#f9f9f9',
                backgroundImage: user? `url(${user.image})` : null,
                backgroundSize: 'cover',
            }}
            width='1.6em'
            height='1.6em'
            borderRadius='50%'
            border='2px solid #f9f9f9'
        />
    )
}

export function UserName({user}) {
    const styles = useStyles()
    return (
        <Box 
            className={styles.user_name}
            style={!user? {
                backgroundColor: '#fafafa',
                borderRadius: '2em',
            }: null}
        >
            {user ? user.name : '\t\t\t'}
        </Box>
    )
}