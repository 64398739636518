import React, { useRef, useState, createContext, useReducer, useContext, useEffect } from 'react'
import firebase from 'firebase/app';
import 'firebase/analytics';
import { useCurrentUser, useUserIdInfo } from './../hooks/user';
import { Box, TextField, Select, MenuItem, Button, makeStyles, Typography } from '@material-ui/core';
import { RelativeDate } from './date';
import { createPost, privateToPublic, publicToPrivate, deletePost, editPost } from './../hooks/post';
import { useDialog } from './../hooks/dialog';
import { LoadingPage } from './loading';
import { Link } from 'react-router-dom';
import { config } from './../config';
import DeleteIcon from '@material-ui/icons/Delete';
import { ExpandableContent } from './expand';
import { useScrollTo } from './scroller';
import VizSensor from 'react-visibility-sensor';

const useStyles = makeStyles({
    '@keyframes fade-in-bottom': {
        from: {
            transform: 'translateY(+15px)',
            opacity: 0,
        },
        to: {
            transform: 'translateY(0)',
            opacity: 1,
        }
    },
    post_full: {
        margin: '1em 0',
        padding: '.75em 1em 0 1em',
        border: '1px solid #e8e8e8',
        borderLeft: '1px solid #e2e2e2',
        backgroundColor: '#fff',
        animation: '$fade-in-bottom 0.2s ease-out',
    },
    post_full_private: {
        margin: '1em 0',
        padding: '.75em 1em 0 1em',
        border: '1px solid #e8e8e8',
        borderLeft: '1px solid #e2e2e2',
        backgroundColor: '#f6f6f6',
        animation: '$fade-in-bottom 0.2s ease-out',
    },
    post_public: {
        margin: '1em 0',
        paddingLeft: '1em',
        borderLeft: `1px solid ${config.colors.public}`,
        animation: '$fade-in-bottom 0.2s ease-out',
    },
    post_private: {
        margin: '1em 0',
        paddingLeft: '1em',
        borderLeft: '1px solid #e8e8e8',
        animation: '$fade-in-bottom 0.2s ease-out',
    },
    delete: {
        cursor: 'pointer',
        opacity: 0.3,
        margin: '0 .5em',
        '&:hover': {
            opacity: 1,
        },
    },
    textfield: {
        '&$disabled': {
            color: '#000',
        }
    },
    disabled: {},
})

const PostContext = createContext(null)
export function Post({post, owned, full, onVisibilityChange}) {
    const input = useRef(null)
    const [text, setText] = useState(post.text)
    const [state, dispatch] = useReducer(
        (state, action) => {
            switch (action.type) {
                case 'start_edit': 
                    return {edit: true}
                case 'cancel_edit':
                    setText(post.text)
                    return {edit: false}
                case 'save_edit': 
                    return {edit: false}
                default:
                    throw new Error()
            }
        }, {edit: false})
    const styles = useStyles()
    const scrollTo = useScrollTo()

    useEffect(() => {
        setText(post.text)
    }, [post.text])

    useEffect(() => {
        if (state.edit && input.current) {
            input.current.focus()
            input.current.selectionStart = input.current.value.length
        }
    }, [input, state.edit])

    const handleClick = e => {
        if (!state.edit) {
            scrollTo(e.currentTarget, - (config.header.standard_height + 10))
        }
    }

    const handleSaveEdit = e => {
        editPost(post, text)
        .then(() => {
            dispatch({type: 'save_edit'})
        })
    }

    return (
        <PostContext.Provider value={[state, dispatch]}>
        <VizSensor 
            onChange={onVisibilityChange}
        >
            <Box 
                onClick={handleClick}
                className={
                    full?
                        post.public ?
                            styles.post_full
                        :
                            styles.post_full_private
                    :
                        post.public?
                            styles.post_public
                        :
                            styles.post_private
                }
            >
                <PostInfo post={post} owned={owned} full={full} on_save={handleSaveEdit} />
                <Box my={1}>
                    <ControlledTextField
                        autoFocus
                        disabled={!state.edit}
                        value={text}
                        onChange={e => setText(e.target.value)}
                        inputRef={input}
                    />
                </Box>
            </Box>
        </VizSensor>
        </PostContext.Provider>
    )
}
Post.defaultProps = {
    owned: false,
    full: false,
}

function PostInfo({post, owned, full, on_save}) {
    const user = useUserIdInfo(post.space)
    const [dialog, showDialog] = useDialog()
    const styles = useStyles()
    const [state, dispatch] = useContext(PostContext)

    const askForPublicApprove = () => {
        return new Promise(resolve => {
            showDialog(
                `Ready to go public?`,
                `Publishing your posts is the best! we just wanna make sure you haven't clicked by mistake.`,
                (close) => <>
                    <Button onClick={close}>
                        Cancel
                    </Button>
                    <Button 
                        variant='contained'
                        onClick={e => resolve(close())}
                        style={{backgroundColor: config.colors.public}} //'#00e327'
                    >
                        Publish
                    </Button>
                </>
            )
        })
    }

    const handlePrivacyChange = (e) => {
        const value = e.target.value
        if (value !== post.public) {
            if (value) {
                askForPublicApprove()
                .then(() => {
                    privateToPublic(post)
                })
            }
            else {
                publicToPrivate(post)
            }
        }
    }

    const askForDeleteApprove = () => {
        return new Promise(resolve => {
            showDialog(
                `Delete Post?`,
                `Deleting a post is permanent.`,
                (close) => <>
                    <Button onClick={close} variant='contained' color='secondary'>
                        Cancel
                    </Button>
                    <Button onClick={e => resolve() && close()}>
                        Delete
                    </Button>
                </>
            )
        })
    }

    const handleDelete = () => {
        if (post.public)
            throw new Error('Cannot delete public post.')
        askForDeleteApprove()
        .then(() => {
            deletePost(post)
        })
    }

    return (
        <Box display='flex' alignItems='center'>
            {dialog}
            <PostImage user={user}/>
            <Box display='flex' flexDirection='column' justifyContent='center' flex='1'>
                { full ? 
                    <PostUserName user={user}/>
                : null}
                { /* TODO: RENAME OWNED */ }
                <PostDate date={ owned? post.created : post.public ? post.published : post.created }/>
            </Box>
            { owned && state.edit === true?
                <>
                    <DeleteIcon
                        fontSize='small'
                        color='primary'
                        className={styles.delete}
                        onClick={handleDelete}
                    />
                    <Button 
                        size='small'
                        color='secondary'
                        variant='contained'
                        onClick={on_save}
                    >
                        Save
                    </Button>
                    <Button size='small' onClick={() => dispatch({type: 'cancel_edit'})}>
                        Cancel
                    </Button>
                </>
            : owned && state.edit === false ?
                <Box>
                    { !post.public?
                        <Button
                            key='edit'
                            size='small'
                            onClick={e => {
                                e.stopPropagation()
                                dispatch({type: 'start_edit'})
                            }}
                        >
                            Edit
                        </Button>
                    : null}
                    <Select
                        key='publish'
                        value={post.public}
                        disableUnderline
                        style={{
                            fontSize: '.8em',
                            fontWeight: 300,
                            color: post.public? config.colors.public : 'inherit',
                        }}
                        onChange={handlePrivacyChange}
                    >
                        <MenuItem value={false}>private</MenuItem>
                        <MenuItem value={true}><Box fontWeight={500}>public</Box></MenuItem>
                    </Select>
                </Box>
            : !owned && !post.public ?
                <div
                style={{
                    fontSize: '.8em',
                    fontWeight: 300,
                }}
                >private</div>
            : null}
        </Box>
    )
}

export function PostLoading() {
    return (
        <Box my={3}>
            <LoadingPage/>
        </Box>
    )
}

function ControlledTextField(props) {
    const styles = useStyles()
    if (props.disabled) {
        return (
            <Typography 
                className={styles.textfield}
                style={{
                    fontSize: '1.3em',
                    fontWeight: 300,
                    lineHeight: '1.5em',
                    padding: '6px 0 7px',
                    whiteSpace: 'pre-wrap',
                }}
                dir='auto'
            >
                <ExpandableContent>
                    {props.value}
                </ExpandableContent>
            </Typography>
        )
    }
    return (
        <TextField
            multiline
            fullWidth
            inputProps={{
                dir: 'auto',
                style: {
                    fontSize: '1.3em',
                    fontWeight: 300,
                    lineHeight: '1.5em',
                    display: '-webkit-box',
                    boxOrient: 'vertical',
                    lineClamp: 5,
                },
            }} 
            InputProps={{
                disableUnderline: true,
                classes: {
                    root: styles.textfield,
                    disabled: styles.disabled
                },
            }}
            {...props}
        />
    )
}

export function PostInput({uid}) {
    const me = useCurrentUser()
    const [text, setText] = useState('')

    const submit = (content) => {
        setText('')
        createPost(uid, content)
        .catch(() => {
            setText(content)
            alert('Error occured, could not submit!')
        })
    }

    const handleBlur = () => {
        const content = text.trim()
        if (content.length > 0) {
            submit(content)
        }
    }

    if (!me || me.uid !== uid) {
        return null
    }

    return (
        <Box my={3} borderLeft='1px solid #e8e8e8' paddingLeft='1em'>
            <InputPostInfo uid={uid} editing={text.length > 0} submit={submit}/>
            <Box my={1}>
                <ControlledTextField
                    placeholder='What have you learned today?'
                    onBlur={handleBlur}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
            </Box>
        </Box>
    )
}

function InputPostInfo({uid, editing, submit}) {
    const user = useUserIdInfo(uid)

    return (
        <Box display='flex' alignItems='center'>
            <PostImage user={user}/>
            <PostUserName user={user}/>
            <Box flex='1' />
            {editing?
                <Button size='small' onClick={submit}>
                    Save
                </Button>
            :null}
        </Box>
    )
}

function PostImage({user}) {
    const handleClick = () => {
        firebase.analytics()
        .logEvent('user_click', {user: user.uid})
    }

    return (
        <Link to={user && user.nick? `/space/${user.nick.replace('%', '.')}` : ''}>
            <Box
                src={user? user.image : null}
                style={{
                    backgroundColor: '#f9f9f9',
                    backgroundImage: user? `url(${user.image})` : null,
                    backgroundSize: 'cover',
                }}
                width='1.6em'
                height='1.6em'
                borderRadius='50%'
                marginRight='.5em'
                border='2px solid #f9f9f9'
                onClick={handleClick}
            />
        </Link>
    )
}

function PostUserName({user}) {
    const handleClick = () => {
        firebase.analytics()
        .logEvent('user_click', {user: user.uid})
    }

    return (
        <Link to={user && user.nick? `/space/${user.nick.replace('%', '.')}` : ''}>
            <Box onClick={handleClick}>
                <Box 
                    whiteSpace='pre'
                    fontSize='.8em'
                    style={!user? {
                        backgroundColor: '#fafafa',
                        borderRadius: '2em',
                    }: null}
                    display='inline-block'
                >
                    {user ? user.name : '\t\t\t'}
                </Box>
            </Box>
        </Link>
    )
}

function PostDate({date}) {
    return (
        <Box fontSize='0.7em' fontWeight='300' style={{color: 'darkslategray'}}>
            <RelativeDate date={date} />
        </Box>
    )
}