import React from 'react';
import './header.css';

import View from '../chitalib/view'
import {MenuToggle, useMenuToggle} from '../chitalib/menu'

export default function Header({children, foreColor, backColor}) {
    const toggle = useMenuToggle()
    return (
        <View className='chitalib-header' style={{backgroundColor: backColor}}>
            <MenuToggle color={foreColor}/>
            <HeaderContent onClick={() => toggle(false)}>{children}</HeaderContent>
        </View>
    )
}
Header.defaultProps = {
    foreColor: '#000',
    backColor: '#fff',
}

function HeaderContent({onClick, children, color}) {
    return <div onClick={onClick} className='chitalib-header-content' style={{color: color}}>
            {children}
    </div>
}
HeaderContent.defaultProps = {color: '#000'}