import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCYHNc42bTr3Q2XRCasEma7szjz5OkMF14",
    authDomain: "chita-space.firebaseapp.com",
    databaseURL: "https://chita-space.firebaseio.com",
    projectId: "chita-space",
    storageBucket: "chita-space.appspot.com",
    messagingSenderId: "658488095857",
    appId: "1:658488095857:web:02863667200e1faed4d7d0",
    measurementId: "G-4QL1RWYLMH"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().useDeviceLanguage()
